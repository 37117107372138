.nav-spacer,
.nav-menu {
  flex: 1;
  /* display: flex; */
  justify-content: center;
}

.nav-menu {
  text-align: left;
}

.nav-menu span {
  padding-left: 20px;
}

.nav-menu a {
  color: white;
  padding: 10px;
}

.event {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  justify-content: center;
}

.event .event-info {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.event .event-image-area {
  max-width: 400px;
  max-height: 250px;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.event .event-image-area .event-image-container {
  width: 100%;
  max-height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event .event-image-container img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.event .event-image-container .event-time-date {
  position: absolute;
  right: 10px;
  top: 10px;
  /* left: 50%; horizontal alignment */
  /* top: 50%; vertical alignment */
  /* transform: translate(-20px, -20px); precise centering; see link below */
  background: white;
  text-align: center;
  padding: 5px;
  padding-bottom: 0px;
  padding-top: 10px;
}

.event .event-image-container .event-time-date .month {
  font-size: 14;
}

.event .event-image-container .event-time-date .day {
  font-size: 22;
  padding-bottom: 2px;
}

.event .event-image-container .event-time-date .time {
  font-size: 12;
  padding: 5px;
  border-top: 1px solid lightgrey;
}

.contact-form * {
  display: block;
}

.contact-form button {
  margin-top: 20px;
}

.center-vert {
  justify-content: center;
  align-items: center;
}

.river.center-hori {
  text-align: center;
}

.river table {
  display: inline-block;
}

.river table p {
  margin: 0px;
}

.river table td:not(:last-child) {
  padding: 0 40px 0 0;
  text-align: left;
}

.river table td:last-child {
  text-align: right;
}

.banner-img {
  width: 100%;
  height: 450px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: var(--spacing-xs);
}

.painting {
  text-align: center;
  margin: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-300);
}

.painting h4 {
  margin-bottom: 0;
}

.painting p {
  margin-top: 0;
}

.painting img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.river {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-direction: column;
  margin-top: 3rem;

  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 0 1rem;

  text-align: center;
}

.contain {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-direction: column;
  margin-top: 3rem;

  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 0 1rem;
}

.contain article {
  width: 100%;
}

.river article {
  width: 100%;
}

.river article button:first-child {
  margin-right: var(--spacing-m);
}

.river > img {
  width: 100%;
  max-width: 550px;
  max-height: 350px;
  object-fit: cover;
}

.tags {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.tags label {
  cursor: pointer;
}

.instagram-media {
  height: 100vw;
}

@media screen and (min-width: 40em) and (max-width: 56.25em) {
  .instagram-media,
  .google-maps {
    height: 60vw;
  }
}

@media screen and (max-width: 40em) {
  .google-maps {
    height: 50vw;
  }
}

@media screen and (min-width: 40em) {
  .event .event-info {
    width: 50%;
    padding-left: 20px;
    padding-top: 0px;
  }

  .event .event-image-area {
    width: 50%;
  }

  .contain article {
    width: 50%;
  }
  .river:nth-child(even) {
    flex-direction: row-reverse;
  }

  .river:nth-child(odd) {
    flex-direction: row;
  }

  .river {
    text-align: left;
  }

  .river article,
  .river > img {
    width: 50%;
  }
}
